import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { bdstatistics, apiURL, mqStatistics } from '../components/census'

const NotFoundPage = () => {

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
  }, [])
  
  return (
    <Layout>
      <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
      <h1>404: Not Found</h1>
    </Layout>
  )
}

export default NotFoundPage
